<template>
  <v-card max-width="500">
    <v-card-title>
      {{ $t('whatAmILookingAt') }}
    </v-card-title>
    <v-card-text class="pa-0">
      <div class="px-6">
        <div class="pb-3">
          {{ $t('underEachSlotYouSeeAnIconAndTwoBars') }}
        </div>
        <div>{{ $t('theTopBarShowsHowManySpotsInASlotHaveBeenBlocked') }}</div>
      </div>
      <v-list dense>
        <v-divider />
        <v-subheader class="px-4">
          {{ $t('LEGEND') }}
        </v-subheader>
        <v-divider />
        <v-list-item>
          <v-list-item-avatar size="24">
            <v-icon color="green">
              mdi-check
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('slotIsAvailable') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar size="24">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('slotHasBeenBlocked') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            color="green"
            size="24"
          />
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('under25Filled') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            color="primary"
            size="24"
          />
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('between25And50Filled') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            color="yellow darken-1"
            size="24"
          />
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('between50And75Filled') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            color="orange"
            size="24"
          />
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('between75And95Filled') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            color="red"
            size="24"
          />
          <v-list-item-content>
            <v-list-item-title class="caption">
              {{ $t('over95Filled') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
name: "AvailabilityLegend"
}
</script>

<style scoped>

</style>