<template>
  <v-dialog
    max-width="1250"
    :value="true"
    @keydown.esc="close"
    @click:outside="close"
    scrollable
  >
    <v-card>
      <v-row class="pa-0 ma-0">
        <v-col
          class="pa-0"
          cols="9"
        >
          <BookingCard
            :booking="booking"
            v-on="$listeners"
            @close="close"
            @rebook="rebook"
          />
        </v-col>
        <v-col
          class="pa-0"
          cols="3"
        >
          <BookingHistory
            :booking="booking"
            v-on="$listeners"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import BookingCard from './BookingCard'
import BookingHistory from './BookingHistory'

export default {
  name: 'BookingDialog',
  props: {
    booking: {
      type: Object,
       default:() => {}
    }
  },
  data() {
    return {
      dialog: true
    }
  },
  components: {
    BookingCard,
    BookingHistory
  },
  methods: {
    close() {
      this.$emit('close')
    },
    rebook(booking) {
      this.$emit('rebook', booking)
    }
  },
}
</script>
