<template>
  <div>
    <v-list-item
      @click="$emit('viewBooking', booking)"
      :key="booking.uuid"
    >
      <v-list-item-avatar
        class="white--text"
        v-if="booking.status === '1'"
        @click="selectBooking"
        color="green"
        :size="32"
      >
        {{ bookingQuantity }}
      </v-list-item-avatar>
      <v-list-item-avatar
        class="white--text"
        v-if="booking.status === '0' && !(booking.redeemedAt > 0)"
        @click="selectBooking"
        color="red"
        :size="32"
      >
        {{ bookingQuantity }}
      </v-list-item-avatar>
      <v-list-item-avatar
        class="white--text"
        v-if="booking.status === '0' && booking.redeemedAt > 0"
        @click="selectBooking"
        color="organge"
        :size="32"
      >
        {{ bookingQuantity }}
      </v-list-item-avatar>
      <v-list-item-content @click="selectBooking">
        <v-list-item-title>
          #{{ booking.booking_id }} - {{ booking.customer && booking.customer.address ? booking.customer.address.name_line : null }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <span
            class="text--primary"
            v-if="booking.item !== null && booking.item !== undefined"
          >
            {{ booking.item.title }}
          </span>
          &mdash;
          <span v-if="booking.location !== null && booking.location !== undefined">
            {{ booking.location.label }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-if="booking.locationVariation !== null && booking.locationVariation !== undefined">
        <v-list-item-title>
          {{ $t('tables') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-btn
            x-small
            color="primary"
          >
            {{ booking.locationVariation.label }}
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-if="booking.locationVariations !== null && booking.locationVariations !== undefined && booking.locationVariations.length > 0">
        <v-list-item-title>
          {{ $t('tables') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-btn
            v-for="locationVariation in booking.locationVariations"
            :key="locationVariation.uuid"
            x-small
            color="primary"
            class="mr-2"
          >
            {{ locationVariation.label }}
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          link
          :href="'tel:' + booking.customer.phone"
        >
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          icon
          link
          :href="'mailto:' + booking.customer.email"
        >
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="pl-2">
        <v-btn
          outlined
          small
          color="error"
          :disabled="cancelling"
          :loading="cancelling"
          @click="cancel"
          v-if="booking.status === true || booking.status === '1'"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          outlined
          small
          color="error"
          :disabled="true"
          v-if="booking.status === false || booking.status === '0'"
        >
          {{ $t('cancelled') }}
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="pl-2">
        <v-btn
          outlined
          small
          color="primary"
          :disabled="true"
          :loading="checkingIn"
          @click="checkin"
          link
          v-if="!(booking.redeemedAt > 0)"
        >
          {{ $t('noShow') }}
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          outlined
          small
          color="primary"
          :disabled="checkingIn || booking.status !== '1' || booking.redeemedAt > 0"
          :loading="checkingIn"
          @click="checkin"
          link
        >
          {{ !(booking.redeemedAt > 0) ? $t('checkIn') : $t('checkedIn') }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      v-if="Array.isArray(booking.upsellLineItems) && booking.upsellLineItems.length > 0"
      dense
      style="padding-left: 60px;"
    >
      <v-container
        fluid
        class="py-0"
      >
        <v-row dense>
          <template v-for="(lineItem, lineItemIndex) in booking.upsellLineItems">
            <v-col
              cols="auto"
              :key="`upsell-item-${lineItemIndex}`"
            >
              <v-card
                class="py-1 px-2"
                color="teal"
                dark
                flat
                style="font-weight: 700; font-size: 18px;"
              >
                {{ `${lineItem.quantity}x - ${lineItem.label}` }}
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-list-item>
  </div>
</template>

<script>
export default {
	name: 'BookingListItem',
  props: {
    booking: {
      type: Object,
      default: () => {}
    }
  },
	data: () => ({
		checkingIn: false,
		cancelling: false,
		showEditBooking: false
	}),
	mounted() {
		// console.log(this.booking)
	},
	computed: {
		bookingQuantity() {
			let bookingQuantity = 1

			if(this.booking.quantity !== null && this.booking.quantity !== undefined) {
				return this.booking.quantity
			}

			return bookingQuantity
		},
		isOnMobile() {
			return this.$vuetify.breakpoint.width < 800
		},
		getLastUpdated() {
			if(!this.booking || !this.booking.changed) {
				return 0
			}
			return this.$store.getters.getDateAndTimeFromTimeString(this.booking.changed)
		},
		hasImages() {
			return !!(this.booking.item && this.booking.item.images && this.booking.item.images.length)
		},
		getImage() {
			if(this.hasImages
					&& this.booking.item.images[0].file
					&& this.booking.item.images[0].file.sources
					&& this.booking.item.images[0].file.sources.thumbnail) {
				return this.booking.item.images[0].file.sources.thumbnail
			} else {
				return null
			}
		}
	},
	methods: {
		editBooking() {
			this.showEditBooking = true
		},
		cancel() {
			this.$emit('showCancelDialog', this.booking)
		},
		checkin() {
			this.checkingIn = true
			this.$store.dispatch('redeemBooking', {
				uuid: this.booking.uuid
			}).finally(() => {
				this.checkingIn = false
				this.booking.redeemedAt = Date.now()
			})
		},
		selectBooking() {
			this.$emit('clicked', this.booking)
		},
		getIsoDate(timeString) {
			return this.$store.getters.getISODate(timeString)
		},
		getIsoTime(timeString) {
			return this.$store.getters.getISOTime(timeString)
		},
		time() {
			if(!this.booking) {
				return 0
			}

			let timeFrom = 0
			let timeTo = 0

			if(this.booking.startsAtTime) {
				timeFrom = this.getIsoTime(this.booking.startsAtTime)
			}
			if(this.booking.endsAtTime) {
				timeTo = this.getIsoTime(this.booking.endsAtTime)
			}

			if(timeFrom === timeTo) {
				return timeFrom
			} else {
				return timeFrom + ' - ' + timeTo
			}
		},
		date() {
			if(!this.booking) {
				return 0
			}

			let dateFrom = 0
			let dateTo = 0

			if(this.booking.startsAtTime) {
				dateFrom = this.getIsoDate(this.booking.startsAtTime)
			}
			if(this.booking.endsAtTime) {
				dateTo = this.getIsoDate(this.booking.endsAtTime)
			}

			if(dateFrom === dateTo) {
				return dateFrom
			} else {
				return dateFrom + ' - ' + dateTo
			}
		},
		dateParse(secondString) {
			let d = new Date(secondString*1000).toISOString()
			return d.slice(0, 10) + ' ' + d.slice(11, 16)
		}
	}
}
</script>
