<template>
  <v-container
    class="fill-height"
    fluid
    v-if="loading"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="auto">
        <v-progress-circular
          indeterminate
          :color="primaryColor || 'primary'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
	export default {
		name: "ProgressCircular",
		props: {
			loading: {
        type: Boolean
      }
		},
		computed: {
			primaryColor() {
				return this.$store.getters.primaryColor
			}
		}
	}
</script>

<style scoped>

</style>
