<template>
  <v-dialog
    v-if="value"
    :value="value"
    @click:outside="close"
    scrollable
    max-width="500"
  >
    <BookingAvailability
      :item="item"
      :channel="channel"
      :location="location"
      @close="close"
      v-on="$listeners"
      :disable-slot-selection="!customer"
    />
  </v-dialog>
</template>

<script>
import BookingAvailability from "@/components/booking/BookingAvailability"

export default {
	name: "AvailabilityDialog",
	components: {
		BookingAvailability
	},
	props: {
		value: {
      type: Boolean
    },
		item: {
      type: String,
      default: ''
    },
		channel: {
      type: String,
      default: ''
    },
		location: {
      type: String,
      default: ''
    },
		customer: {
      type: String,
      default: ''
    },
	},
	methods: {
		close() {
			this.$emit('closed')
		}
	}
}
</script>

<style scoped>

</style>