<template>
  <v-list-item
    link
    @click="selectFilter"
  >
    <v-list-item-content>
      <v-list-item-title>{{ filter.label }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
/**
 * filter object structure
 * label: String
 * key: String
 * type: String
 * options: Array
 */
export default {
	name: "FilterListItem",
	props: {
		filter: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		selectFilter() {
			this.$emit('filterSelected', this.filter)
		}
	}
}
</script>