<template>
  <v-dialog
    :value="true"
    max-width="500px"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('filters') }}</v-card-title>
      <v-card-subtitle>{{ $t('selectAvailableFilter') }}</v-card-subtitle>
      <FilterList
        @filterSelected="selectFilter"
        class="px-5 pb-3"
        :filters="filters"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import FilterList from "@/components/universalFilter/FilterList"

export default {
    name: 'FilterDialog',
    components: {
        FilterList
    },
    props: {
      filters: {
        type: Array,
        default: () => []
      }
    },
    methods: {
        selectFilter(filter) {
			this.$emit('filterSelected', filter)
		},
        close() {
            this.$emit('close')
        }
    }
}
</script>
