<template>
  <div>
    <FilterDialog
      v-if="showFiltersInDialog"
      :filters="reactiveFilters"
      @filterSelected="filterSelected"
      @close="close"
    />
    <FilterForm
      v-if="selectedFilters.length > 0"
      :selected-filters="selectedFilters"
      @applyFilters="applyFilters"
      @remove="removeFilter"
      :filtering="filtering"
    />
  </div>
</template>

<script>

import FilterDialog from "./FilterDialog"
import FilterForm from "./FilterForm"

export default {
    name: 'UniversalFilter',
    props: {
        filters: {
            type: Array, 
            default: () => []
        },
        filtering: {
            type: Boolean
        }, 
        namespace: {
            type: String,
            default: ''
        }
    },
    components: {
        FilterDialog,
        FilterForm
    },
    data() {
        return {
            selectedFilters: [],
            reactiveFilters: []
        }
    },
    computed: {
        showFiltersInDialog: {
            get() {
                return this.$store.state.showFilters
            },
            set(value) {
                this.$store.commit('updateShowFilters', value)
            }
        },
        organization() {
            return this.$store.state.organization
        },
        persistantKey() {
            return 'universalFilter:' + this.$store.state.organization.uuid + ':' + this.namespace
        },
        hasNamespace() {
            return this.namespace !== null && this.namespace !== undefined
        }
    },
    watch: {
        selectedFilters(newFilters) {
            if(this.hasNamespace) {
                this.persistFilters(newFilters)
            }
        }
    },
    methods: {
        persistFilters(filters) {
            localStorage.setItem(this.persistantKey, JSON.stringify(filters))
        },
        close() {
            this.showFiltersInDialog = false
        },
        showFilters() {
            //this.showFiltersInDialog = true
        },
        filterSelected(filter) {
            this.selectedFilters.push(filter)
        },
        applyFilters(selectedFilters) {

            if(this.hasNamespace) {
                this.persistFilters(selectedFilters)
            }

            this.$emit('applyFilters', selectedFilters)
        },
        removeFilter(index) {
            this.selectedFilters.splice(index, 1)
            if(this.selectedFilters.length === 0) {
                 this.$emit('applyFilters', null)
            }
        }
    },
    mounted() {
        this.reactiveFilters = JSON.parse(JSON.stringify(this.filters))
        if(this.hasNamespace) {
            const persistedFilters = localStorage.getItem(this.persistantKey)
            if(persistedFilters !== null && persistedFilters !== undefined) {
                this.selectedFilters = JSON.parse(persistedFilters)
                if(this.selectedFilters.length > 0) {
                    this.$emit('applyFilters', this.selectedFilters)
                }
            }
        }
    }
}
</script>