<template>
  <v-card
    tile
    style="height:100%;"
    class="overflow-y-auto"
    max-height="644"
  >
    <v-card-title style="padding-bottom:12px">
      {{ $t('bookingHistory') }}
    </v-card-title>
    <v-divider />
    <div v-if="bookings !== null">
      <BookingListSimple
        :load-items-callback="getBookings"
        v-on="$listeners"
        :fixed-filters="customerFilter"
      />
    </div>
  </v-card>
</template>

<script>

import BookingListSimple from "./BookingListSimple"

export default {
    name: 'BookingHistory',
    props: {
      booking: {
        type: Object,
        default: () => {}
      },
      bookings: {
        type: Array,
        default: () => []
      }
    },
    components: {
        BookingListSimple
    },
    data: () => ({
 
    }),
    computed: {
      customerFilter() {
        return [
          {
            key: 'customer.uuid',
            value: this.customer.uuid,
            operator: 'equals'
          }
        ]
      },
      customer() {
        if(this.booking.customer){
          return this.booking.customer
        }
        return null
      }, 
    },
    methods: {
      getBookings({conditions}) {
        return this.$store.dispatch('getBookingsByConditions', {conditions})
      }, 
    }, 
    mounted() {
      
    }
}
</script>
