<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col
        cols="12"
        v-if="slicedBookings === null || isGettingBookings"
      >
        <template v-for="index in 10">
          <v-skeleton-loader
            ref="skeleton"
            type="list-item-avatar-two-line"
            class="mx-auto"
            :key="index"
          />
        </template>
      </v-col>
      <v-col
        cols="12"
        v-if="slicedBookings !== null"
        class="py-0"
      >
        <v-container
          fluid
          class="py-0"
        >
          <v-row class="mt-3">
            <v-col
              cols="6"
              class="pt-0 pb-2"
            >
              <v-row>
                <v-col
                  cols="6"
                  class="pt-0 pb-0"
                >
                  <v-menu
                    v-model="selectDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    max-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedDate"
                        :label="$t('date')"
                        outlined
                        hide-details
                        dense
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        style="border-left: 0; border-right: 0;"
                      />
                    </template>
                    <v-date-picker
                      v-model="selectedDate"
                      no-title
                      @input="selectDate = false"
                      style="height: 350px;"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  class="pt-0 pb-0 pr-0"
                  style="max-width: 80px;"
                >
                  <v-btn
                    tile
                    block
                    outlined
                    style="border-right: 0;"
                  >
                    <v-icon
                      large
                      color="green darken-2"
                      @click="prevDay"
                      :disabled="isGettingBookings"
                    >
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  class="pt-0 pb-0 pl-0"
                  style="max-width: 80px;"
                >
                  <v-btn
                    tile
                    block
                    outlined
                    style="border-left: 0;"
                  >
                    <v-icon
                      large
                      color="green darken-2"
                      @click="nextDay"
                      :disabled="isGettingBookings"
                    >
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="6"
              class="text-right pt-0 pb-0"
            >
              <v-btn
                dark
                color="green"
                class="mr-2"
                @click="addBooking"
              >
                {{ $t('addBooking') }}
              </v-btn>
              <v-btn
                outlined
                @click="addBooking"
              >
                {{ $t('walkIn') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-select
                v-if="!isEventBooking"
                dense
                hide-details
                :label="$t('items')"
                outlined
                v-model="selectedItems"
                :items="eventItems"
                item-text="title"
                item-value="uuid"
                multiple
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-if="!isEventBooking"
                dense
                hide-details
                :label="$t('location')"
                outlined
                v-model="selectedLocation"
                :items="locations"
                item-text="label"
                item-value="uuid"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-if="!isEventBooking"
                dense
                hide-details
                :label="$t('bookingState')"
                outlined
                v-model="selectedBookingState"
                :items="bookingStates"
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col
              cols="3"
              class="text-right"
            >
              <div class="pt-2 overline">
                {{ $t('displaying') }} {{ loadedBookingLength }} {{ $t('of') }}{{ actualBookingLength }} {{ $t('bookingsLC') }}
              </div>
            </v-col>
            <v-col
              cols="3"
              class="text-right py-0"
            >
              <div class="pt-4 overline">
                <div>{{ actualBookingLength }} {{ $t('bookingsLC') }}, {{ pax }} PAX, {{ paxRedeemed }} {{ $t('checkedIn') }}</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-col
          cols="12"
          v-if="slicedBookings === null || isGettingBookings"
        >
          <template v-for="index in 10">
            <v-skeleton-loader
              ref="skeleton"
              type="list-item-avatar-two-line"
              class="mx-auto"
              :key="index"
            />
          </template>
        </v-col>
        <v-col>
          <UniversalFilter
            v-if="isEventBooking"
            namespace="event"
            :filters="filters"
            class="ml-2 mr-2 mt-2"
            @applyFilters="filterBookings"
            :filtering="filtering"
          />
        </v-col>
        <template v-for="bookingStartsAtTimeEntry in bookingStartsAtTimeIndex">
          <v-row
            :key="bookingStartsAtTimeEntry + '-header'"
            align="center"
            dense
            style="background-color: rgba(0, 0, 0, 0.05)"
            class="mx-0"
          >
            <v-col
              v-if="(isEventBooking || showCustomDateRange) && bookingDates.some(d => d.first === bookingStartsAtTimeEntry)"
              cols="12"
              class="py-0 px-0"
            >
              <v-subheader
                :key="bookingStartsAtTimeEntry + '-date-header'"
                class="font-weight-bold title"
                style="background-color: rgba(0, 0, 0, 0.12)"
              >
                {{ bookingStartsAtTimeEntry | moment('DD/MM/YYYY') }} - {{ bookingDates.find(d => d.first === bookingStartsAtTimeEntry).bookingsCount }} {{ $tc('bookings', bookingDates.find(d => d.first === bookingStartsAtTimeEntry).bookingsCount) }}
              </v-subheader>
            </v-col>
            <v-col class="py-0">
              <v-subheader :key="bookingStartsAtTimeEntry + '-time-header'">
                {{ bookingStartsAtTimeEntry | moment('HH:mm') }} - {{ bookingsActiveInStartsAtTimeIndex(bookingStartsAtTimeEntry).length }} {{ $tc('bookings', bookingsInStartsAtTimeIndex(bookingStartsAtTimeEntry).length) }} - {{ bookingsCancelledInStartsAtTimeIndex(bookingStartsAtTimeEntry).length }} {{ $t('canceled') }}
              </v-subheader>
            </v-col>
            <v-col
              cols="12"
              md="auto"
              lg="auto"
              class="pt-0 pb-2 py-lg-0"
            >
              <v-btn
                style="background-color: #FFFFFF"
                small
                outlined
                :key="bookingStartsAtTimeEntry + '-btn'"
                color="primary"
                @click="addEventAtSpecificTime(bookingStartsAtTimeEntry)"
                :disabled="!canAddBooking()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>{{ $t('addBooking') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider :key="bookingStartsAtTimeEntry + '-d'" />
          <v-list
            :key="bookingStartsAtTimeEntry"
            class="pt-0 pb-4"
          >
            <template v-for="(key, index) in bookingsGroupedInStartsAtTimeIndexes(bookingStartsAtTimeEntry)">
              <GroupedBookingListItem
                :key="key"
                v-if="bookingsGroupedInStartsAtTimeIndex(bookingStartsAtTimeEntry)[key].length > 1"
                :bookings="bookingsGroupedInStartsAtTimeIndex(bookingStartsAtTimeEntry)[key]"
                @showCancelDialog="confirmCancelDialog"
                @clicked="viewBooking"
              />
              <BookingListItem
                @viewBooking="viewBooking"
                v-else
                :key="key"
                :booking="bookingsGroupedInStartsAtTimeIndex(bookingStartsAtTimeEntry)[key][0]"
              />
              <v-divider
                :key="index"
                inset
              />
            </template>
          </v-list>
          <v-divider :key="'d-'+ bookingStartsAtTimeEntry" />
        </template>
        <div
          v-if="loadedBookingLength !== actualBookingLength"
          v-intersect.quiet="loadMore"
        >
          <ProgressCircular :loading="isLoadingMore" />
        </div>
        <v-row justify="center">
          <v-col
            cols="auto"
            class="overline"
          >
            {{ $t('displaying') }} {{ loadedBookingLength }} {{ $t('of') }} {{ actualBookingLength }} {{ $t('bookingsLC') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <BookingDialog
      @close="selectedBooking = null"
      v-if="selectedBooking !== null"
      :booking="selectedBooking"
      v-on="$listeners"
      @overwriteBooking="overwriteBooking"
    />
    <AvailabilityDialog
      v-model="showAvailabilityDialog"
      @closed="showAvailabilityDialog = false"
      v-if="showAvailabilityDialog"
      :item="bookingItemForAvailability"
      :location="bookingLocationForAvailability"
      :channel="bookingChannelForAvailability"
      :customer="bookingCustomerForAvailability"
      @selectedSlot="selectSlot"
    />
    <ConfirmDialog
      v-model="showConfirmCancel"
      v-if="showConfirmCancel"
      @closed="closeConfirmCancelDialog"
      @confirmed="confirmCancel"
      :items="cancelItemsStrings"
      :title="$t('confirmAction')"
      :sub-title="$t('pleaseConfirmCancellationOfItemsBelow')"
      :loading="cancellingBooking"
    />
  </v-container>
</template>

<script>

import GroupedBookingListItem from "../components/GroupedBookingListItem"
import ProgressCircular from "./common/ProgressCircular"
import BookingDialog from "../components/booking/BookingDialog"
import AvailabilityDialog from "@/components/booking/AvailabilityDialog"
import ConfirmDialog from "@/components/common/ConfirmDialog"
import BookingListItem from "../components/BookingListItem"
import UniversalFilter from "../components/universalFilter/UniversalFilter"
import moment from "moment"

export default {
	name: 'BookingList',
	props: {
		customer: {
			type: Object,
			default: () => {}
		},
		bookings: {
			type: Array,
			default: () => []
		},
		isEventBooking: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ConfirmDialog,
		AvailabilityDialog,
		ProgressCircular,
		GroupedBookingListItem,
		BookingDialog,
		BookingListItem,
		UniversalFilter
	},
	data() { 
		return {
			filtering: false,
			filters: [
				{
					key: 'customer.address.name_line',
					label: this.$t('customerName'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'location.label',
					label: this.$t('location'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'customer.phone',
					label: this.$t('phone'),
					type: 'text',
					value: 'null',
					operator: 'contains'
				}
			],
			selectedItems: [],
			filteredBookings: null,
			startIndex: 0,
			isLoadingMore: false,
			bottom: false,
			currentLength: 0,
			showRange: false,
			length: 500,
			step: 100,
			selectedBooking: null,
			filterBookingsByBookingItem: null,
			bookingDatesToShow: [],
			showFilter: false,
			filterList: [],
			showAddBookingDialog: false,
			showAvailabilityDialog: false,
			bookingTime: null,
			bookingItem: null,
			bookingItemForAvailability: null,
			bookingLocationForAvailability: null,
			bookingChannelForAvailability: null,
			bookingCustomerForAvailability: null,
			selectedSlot: null,
			showConfirmCancel: false,
			cancelItems: [],
			showCustomDateRange: null,
			selectDate: false
		}
	},
	methods: {
		prevDay() {
			const date = new Date(this.selectedDate)
			this.selectedDate = moment(date.getTime()).subtract(1, 'day').format("YYYY-MM-DD")
		},
		nextDay() {
			const date = new Date(this.selectedDate)
			this.selectedDate = moment(date.getTime()).add(1, 'day').format("YYYY-MM-DD")
		},
		filterBookings(filters) {
			if(filters === null) {
				this.filteredBookings = null
				return
			}
			this.filtering = true
			this.$store.getters.applyFilters({ $worker: this.$worker, filters: filters, listOfObjects: this.bookings }).then(filteredBookings => {
				this.filteredBookings = filteredBookings
				this.filtering = false
				this.$emit('filterBookingsExport', this.filteredBookings)
			})
		},
		confirmCancelDialog(e) {
			this.cancelItems = []
			if(!Array.isArray(e)) {
				this.cancelItems = [e]
			} else {
				this.cancelItems = e
			}
			this.showConfirmCancel = true
		},
		closeConfirmCancelDialog() {
			this.cancelItems = []
			this.showConfirmCancel = false
		},
		confirmCancel() {
			let items = this.cancelItems
			if(!Array.isArray(items)) {
				this.$store.commit('updateActionError', {
					message: this.$t('couldNotCancelBooking'),
					subMessage: this.$t('noSelectedBookingsDetected')
				})
				return
			}

			items = items.filter(item => !item.redeemedAt)
			if(items.length <= 0) {
				this.$store.commit('updateActionError', {
					message: this.$t('couldNotCancelBooking'),
					subMessage: this.$t('noSelectedBookingsDetected')
				})

        		return
			}

			this.$store.dispatch('cancelMultipleBookings', items).then(results => {
				this.showConfirmCancel = false
				if(!results) {
					this.$store.commit('updateActionError', {
						message: this.$t('couldNotCancelBooking'),
					})
				} else if(results.some(result => !result)) {
					this.$store.commit('updateActionError', {
						message: this.$t('couldNotCancelBookings'),
					})
				} else {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: this.$t('successfullyCancelledBookings')
					})
				}
			})
		},
		showCustomRange(e) {
			if(e === true) {
				this.bookingDatesToShow = []
			}
			this.showCustomDateRange = e
		},
		viewBooking(booking) {
			this.selectedBooking = booking
		},
		canAddBooking() {
			return true
			// if(this.filterBookingsByBookingItem) {
			// 	return true
			// } else {
			// }
		},
		addBooking() {
			this.bookingItem = this.filterBookingsByBookingItem
			this.showAddBookingDialog = true
		},
		addEventAtSpecificTime(time) {
			this.bookingTime = time * 1000
			this.bookingItem = this.filterBookingsByBookingItem
			this.showAddBookingDialog = true
		},
		bookingsGroupedInStartsAtTimeIndexes(startsAtTime) {
			const bookings = this.bookingsInStartsAtTimeIndex(startsAtTime)

			const bookingsGrouped = {}
			for(let i in bookings) {

				const booking = bookings[i]
				//console.log(booking.customer.uuid + ':' + booking.item.uuid + ':' + booking.startsAtTime + ':' + booking.endsAtTime)
				if(booking.customer !== null){
					const key = booking.customer.uuid + ':' + booking.item.uuid + ':' + booking.startsAtTime + ':' + booking.endsAtTime

					if(!bookingsGrouped[key]) {
						bookingsGrouped[key] = []
					}

					bookingsGrouped[key].push(booking)
				}
			}
			return Object.keys(bookingsGrouped)
		},
		bookingsGroupedInStartsAtTimeIndex(startsAtTime) {
			const bookings = this.bookingsInStartsAtTimeIndex(startsAtTime)

			const bookingsGrouped = {}

			for(let i in bookings) {
				const booking = bookings[i]
				if(booking.customer !== null) {
					const key = booking.customer.uuid + ':' + booking.item.uuid + ':' + booking.startsAtTime + ':' + booking.endsAtTime

					if(!bookingsGrouped[key]) {
						bookingsGrouped[key] = []
					}
					bookingsGrouped[key].push(booking)
				}

			}

			return bookingsGrouped
		},
		bookingsInStartsAtTimeIndex(startsAtTime) {
			return this.slicedBookings.filter(booking => booking.startsAtTime === startsAtTime)
		},
		bookingsActiveInStartsAtTimeIndex(startsAtTime) {
			return this.slicedBookings.filter(booking => booking.startsAtTime === startsAtTime && booking.status === '1')
		},
		bookingsCancelledInStartsAtTimeIndex(startsAtTime) {
			return this.slicedBookings.filter(booking => booking.startsAtTime === startsAtTime && booking.status === '0')
		},
		getBookingsByStartAndEnd(startInMillis, endInMillis) {
			this.gettingCustomRange = true

			const payload = {
				startTimeInMillis: startInMillis,
				endTimeInMillis: endInMillis
			}

			this.$store.dispatch('getBookingsByDate', payload).finally(() => {
				this.bookingDatesToShow = this.bookingDates
				this.gettingCustomRange = false
			})
		},
		bookingDateIsAvailable(bookingStartsAtTimeEntry) {
			if(!this.showCustomDateRange) {
				return false
			}
			const date = new Date(bookingStartsAtTimeEntry*1000).toISOString().substr(0,10)
			for(let i = 0; i < this.bookingDatesToShow.length; i++) {
				if(this.bookingDatesToShow[i].date === date && this.bookingDatesToShow[i].count === 0) {
					this.bookingDatesToShow[i].count += 1
					return true
				}
			}
			return false
		},
		bottomVisible() {
			const scrollY = window.scrollY
			const visible = document.documentElement.clientHeight
			const pageHeight = document.documentElement.scrollHeight
			const bottomOfPage = visible + scrollY >= pageHeight
			return bottomOfPage || pageHeight < visible
		},
		loadMore: function() {
			if(this.actualBookingLength > this.length) {
				this.isLoadingMore = true
				this.length = this.length + this.step
			}
		},
		startOfDay(timestamp) {
			return new Date(timestamp).setHours(0,0,0,0)
		},
		endOfDay(timestamp) {
			return new Date(timestamp).setHours(23,59,59,999)
		},
		updateStartTime(value) {
			this.startTimeInMillis = value
		},
		updateEndTime(value) {
			this.endTimeInMillis = value
		},
		selectSlot(slot) {
			this.selectedSlot = slot
		},
		overwriteBooking(result) {
			this.selectedBooking = result
		},
		loadMoreBookings() {
			if(this.filtering) {
				return
			}
			this.isLoadingMore = true
			this.length = this.length + parseInt(this.step)
		}
	},
	computed: {
		selectedDate: {
			set(value) {
				this.$store.commit('updateSelectedDate', value)
			},
			get() {
				return this.$store.state.selectedDate
			}
		},
		selectedLocation: {
			get() {
				return this.$store.state.selectedLocation
			},
			set(value) {
				this.$store.commit('updateSelectedLocation', value)
			}
		},
		selectedBookingState: {
			get() {
				return this.$store.state.selectedBookingState
			},
			set(value) {
				this.$store.commit('updateSelectedBookingState', value)
			}
		},
		bookingStates() {
		return this.$store.state.bookingStates
		},
		eventItems() {
			return this.$store.state.items.filter(item => item.type === 'event' && item.status === true).sort((i1, i2) => i1.title.localeCompare(i2.title))
		},
		isGettingBookings() {
			return this.$store.state.isUpdatingBookings
		},
		cancellingBooking() {
			return this.$store.getters.cancellingBooking
		},
		colorIsLight() {
			return this.$store.getters.colorIsLight(this.primaryColor)
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		bookingStartsAtTimeIndex() {
			const index = []

			for(let i in this.slicedBookings) {
				const booking = this.slicedBookings[i]
				if(!index.includes(booking.startsAtTime)) {
					index.push(booking.startsAtTime)
				}
			}

			return index
		},
		bookingDates() {
			const dates = []
			for(let i = 0; i < this.bookingStartsAtTimeIndex.length; i++) {
				const date = new Date(this.bookingStartsAtTimeIndex[i] * 1000).toISOString().substr(0,10)
				if(dates.length === 0 || dates.every(d => d.date !== date)) {
					let o = {
						date: date,
						first: this.bookingStartsAtTimeIndex[i],
						bookingsCount: 0
					}

					dates.push(o)
				}
				dates.find(d => d.date === date).bookingsCount += this.bookingsInStartsAtTimeIndex(this.bookingStartsAtTimeIndex[i]).length
			}
			return dates
		},
		systemTime() {
			return this.$store.state.systemTime
		},
		query: function() {
			return this.$store.state.searchQuery
		},
		locations() {
			return [{
        label: 'All',
        uuid: null
      }].concat(this.$store.state.locations.filter(location => location.label !== '' && location.label !== null).sort((l1,l2) => l1.label.localeCompare(l2.label)))
		},
		slicedBookings() {
			if(this.filteredBookings !== null && typeof this.filteredBookings !== 'undefined') {
				return this.filteredBookings.slice(this.startIndex, this.length)
			}
      if(this.bookings !== null && typeof this.bookings !== 'undefined') {
        return this.bookings.slice(this.startIndex, this.length)
      }
			return []
		},
		loadedBookingLength: function() {
			return (this.slicedBookings && Array.isArray(this.slicedBookings)) ? this.slicedBookings.length : 0
		},
		actualBookingLength: function() {
			return (this.bookings && Array.isArray(this.bookings)) ? this.bookings.length : 0
		},
		bookingItems() {
			let items = this.$store.getters.bookingItems.filter(item => item.type === 'event').map(i => {
				return {
					text: i.title,
					value: i.uuid
				}
			})

			return [{
				text: this.$t('all'),
				value: null,
			}].concat(items)
		},
		cancelItemsStrings() {
			if(Array.isArray(this.cancelItems) && this.cancelItems.length > 0) {
				return this.cancelItems.map(item => {
					let itemString = []

					if(item.booking_id) {
						itemString.push(item.booking_id)
					}

					if(item.item && item.item.title) {
						itemString.push(item.item.title)
					}

					if(item.startsAtTime) {
						let d = new Date(item.startsAtTime * 1000).toISOString()
						itemString.push(d.substr(11, 5))
						itemString.push(d.substr(0,10))
					}

					if(item.customer && item.customer.address && item.customer.address.name_line) {
						itemString.push(item.customer.address.name_line)
					}

					itemString = itemString.join(' - ')
					return itemString
				})
			}

			if(Array.isArray(this.cancelItems) && this.cancelItems.length <= 0) {
				return ''
			}

			if(this.cancelItems.title) {
				return this.cancelItems.title
			}
			if(this.cancelItems.name) {
				return this.cancelItems.name
			}
			if(this.cancelItems.label) {
				return this.cancelItems.label
			}
			return ''
		},
    pax() {
      let pax = 0

      if(this.slicedBookings === null || this.slicedBookings === undefined) {
        return pax
      }

      for(let i in this.slicedBookings) {
        const booking = this.slicedBookings[i]
        pax = pax + booking.quantity
      }

      return pax
    },
    paxRedeemed() {
      let pax = 0

      if(this.slicedBookings === null || this.slicedBookings === undefined) {
        return pax
      }

      for(let i in this.slicedBookings) {
        const booking = this.slicedBookings[i]

        if(booking.redeemedAt === 0 || booking.redeemedAt === null || booking.redeemedAt === undefined) {
          continue
        }

        pax = pax + booking.quantity
      }

      return pax
    },
	genericDialog() {
		return this.$store.state.genericDialog
	}
	},
	watch: {
		currentLength(length) {
			if(length === this.actualBookingLength || this.actualBookingLength === this.loadedBookingLength || this.actualBookingLength === 0) {
				this.isLoadingMore = false
			}
		},
		selectedItems(items) {

			if(items === null || items.length === 0) {
				this.filteredBookings = null
				return
			}

			this.filteredBookings = this.bookings.filter(booking => items.includes(booking.item.uuid))
		},
		// bottom(bottom) {
		//   if(bottom) {
		//     this.loadMore()
		//   }
		// }
		genericDialog(value) {
			if(!value) {
				this.showAddBookingDialog = false 
			}
		},
		showAddBookingDialog(value) {
			if(value) {
				this.$store.commit('updateGenericDialog', {
					'component': 'components/booking/AddBookingCard', 
					'props': {
					'bookingObject': this.selectedBooking, 
					'bookingItem': this.bookingItem,
					'bookingTime': this.bookingTime
					}
				})
			}
		}
	},
	created() {
		// window.addEventListener('scroll', () => {
		//   this.bottom = this.bottomVisible()
		// })
		this.currentLength = parseInt(this.length)
	},
  mounted() {
    if(!this.selectedLocation || this.selectedLocation === 'null') {
      this.selectedLocation = null
    }

  }
}
</script>
