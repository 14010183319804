<template>
  <v-list>
    <template v-for="(filter, index) in filters">
      <FilterListItem
        :key="index"
        @filterSelected="selectFilter"
        :filter="filter"
      />
    </template>
  </v-list>
</template>

<script>
import FilterListItem from "@/components/universalFilter/FilterListItem"

export default {
	name: "FilterList",
	components: {
		FilterListItem
	},
	props: {
		filters: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		noFiltersDefined() {
			return this.filters === undefined || this.filters === null || !Array.isArray(this.filters) || (Array.isArray(this.filters) && this.filters.length <= 0)
		}
	},
	methods: {
		selectFilter(filter) {
			this.$emit('filterSelected', filter)
		}
	}
}
</script>