<template>
  <div>
    <v-list-item @click="expand">
      <v-list-item-avatar
        :color="disableCancelAll ? 'red' : (someCancelled ? 'orange' : 'green')"
        class="white--text"
      >
        {{ bookingQuantity }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ firstBooking.customer.address.name_line }}</v-list-item-title>
        <v-list-item-subtitle>{{ firstBooking.item.title }} <span v-if="firstBooking.externalNote !== null">{{ firstBooking.internalNote }}</span></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-title>{{ bookingGroupStartsAtTime }} - {{ bookingGroupEndsAtTime }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          outlined
          color="error"
          v-if="disableCancelAll"
          depressed
          :disabled="true"
        >
          {{ $t('cancelled') }}
        </v-btn>
        <v-btn
          outlined
          color="error"
          v-else
          depressed
          @click="cancelGroupedBookings"
          @click.prevent="expand"
          :disabled="disableCancelAll || cancelling"
          :loading="cancelling"
        >
          {{ $t('cancelAll') }}
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          color="primary"
          outlined
          :disabled="checkingIn || disableCancelAll"
          :loading="checkingIn"
          v-if="notAllCheckedIn(bookings)"
          @click="checkinMultiple(bookings)"
          @click.prevent="expand"
          link
        >
          {{ $t('checkinAll') }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          :disabled="true"
          :loading="checkingIn"
          link
          v-if="!notAllCheckedIn(bookings)"
        >
          {{ $t('checkedIn') }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      dense
      v-if="firstBooking.internalNote !== null && firstBooking.internalNote !== ''"
      style="padding-left: 80px;"
    >
      <v-list-item-avatar>
        <v-icon>mdi-message-bulleted</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $t('internalNote') }}
        </v-list-item-subtitle>
        {{ firstBooking.internalNote }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      dense
      v-if="firstBooking.externalNote !== null && firstBooking.externalNote !== ''"
      style="padding-left: 80px;"
    >
      <v-list-item-avatar>
        <v-icon>mdi-message-text</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $t('externalNote') }}
        </v-list-item-subtitle>
        {{ firstBooking.externalNote }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-if="Array.isArray(firstBooking.upsellLineItems) && firstBooking.upsellLineItems.length > 0"
      dense
      style="padding-left: 60px;"
    >
      <v-container
        fluid
        class="py-0"
      >
        <v-row dense>
          <template v-for="(lineItem, lineItemIndex) in firstBooking.upsellLineItems">
            <v-col
              cols="auto"
              :key="`upsell-item-${lineItemIndex}`"
            >
              <v-card
                class="py-1 px-2"
                color="teal"
                dark
                flat
                style="font-weight: 700; font-size: 18px;"
              >
                {{ `${lineItem.quantity}x - ${lineItem.label}` }}
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-list-item>
    <v-divider
      v-if="expanded"
      inset
    />
    <div
      class="ml-10 pl-8"
      v-if="expanded"
    >
      <template v-for="(booking, index) in bookings">
        <BookingListItem
          :booking="booking"
          :key="booking.uuid"
          v-on="$listeners"
        />
        <v-divider
          :key="booking.uuid+'-'+index"
          v-if="index < bookings.length - 1"
        />
      </template>
    </div>
  </div>
</template>

<script>
import BookingListItem from "../components/BookingListItem"

export default {
    name: 'GroupedBookingListItem',
    props: {
      bookings: {
        type: Array,
        default: () => []
      }
    },
    components: {
      BookingListItem
    },
    data() {
        return {
            expanded: false,
            checkingIn: false,
            cancelling: false
        }
    },
    methods: {
        expand() {
            this.expanded = !this.expanded
        },
        cancelGroupedBookings() {
          this.$emit('showCancelDialog', this.bookings)
        },
        checkinMultiple(bookings) {
          this.checkingIn = true
          let promises = []

          for(let j = 0; j < bookings.length; j++) {
            if(bookings[j].status === '0') {
              continue
            }
            promises.push(
                this.$store.dispatch('redeemBooking', {
                  uuid: bookings[j].uuid
                }).then(() => {
                  bookings[j].redeemedAt = Date.now()
                  Promise.resolve(bookings[j])
                })
            )
          }
          Promise.all(promises).then(() => {
            this.checkingIn = false
          })
        },
        notAllCheckedIn(bookings) {
          for(let i = 0; i < bookings.length; i++) {
            if(!bookings[i].redeemedAt) {
              return true
            }
          }
          return false
        },
    },
    computed: {
        bookingQuantity() {
          let quantity = 0

          for(let i in this.bookings) {
            const booking = this.bookings[i]
            const bookingQuantity = booking.quantity || 1
            quantity = quantity + bookingQuantity
          }

          return quantity
        },
        firstBooking() {
            return this.bookings[0]
        },
        disableCancelAll() {
          return this.bookings.every(booking => booking.status === '0' || booking.redeemedAt)
        },
        someCancelled() {
          return this.bookings.some(booking => booking.status === '0')
        },
        bookingGroupStartsAtTime() {
          let date = new Date(this.firstBooking.startsAtTime * 1000)
          let hours = date.getHours()
          let minutes = date.getMinutes()
          hours = hours < 10 ? '0' + hours : hours
          minutes = minutes < 10 ? '0' + minutes : minutes
          return hours + ':' + minutes
        },
        bookingGroupEndsAtTime() {
          let date = new Date(this.firstBooking.endsAtTime * 1000)
          let hours = date.getHours()
          let minutes = date.getMinutes()
          hours = hours < 10 ? '0' + hours : hours
          minutes = minutes < 10 ? '0' + minutes : minutes
          return hours + ':' + minutes
        }
    }
}
</script>
