<template>
  <v-card elevation="2">
    <v-card-title>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="auto"
          class="py-0"
        >
          {{ $t('currentAvailability') }}
        </v-col>
        <v-col
          cols="auto"
          class="py-0"
        >
          <v-menu
            offset-x
            nudge-right="30"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-help
                </v-icon>
              </v-btn>
            </template>
            <AvailabilityLegend />
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-title class="py-0">
      <v-date-picker
        color="primary"
        v-model="selectedDate"
        full-width
        class="elevation-0"
        no-title
        :disabled="disabled"
        style="height: 380px"
      />
    </v-card-title>
    <v-card-text class="pa-0 visible-scroll">
      <v-sheet
        color="grey lighten-3"
        class="px-3"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              v-if="gettingAvailableTimeSlots"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
              <div class="title pt-3">
                {{ $t('gettingAvailableTimeSlots') }}
              </div>
            </v-col>
            <v-col
              cols="12"
              v-if="slotGroups !== null && slotGroups.length > 0 && !gettingAvailableTimeSlots"
              class="py-0"
            >
              <template v-for="(group, index) in slotGroups">
                <v-row :key="index+'-header'">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-divider />
                    <v-subheader class="v-subheader">
                      {{ $t('kl') }} {{ group }}
                    </v-subheader>
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row
                  :key="index+'-slots'"
                  dense
                  class="py-2"
                >
                  <template v-for="(slot, index2) in slots[group]">
                    <v-col
                      cols="auto"
                      :key="index2"
                    >
                      <v-row
                        dense
                        class="py-0"
                      >
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-btn
                            block
                            color="white"
                            depressed
                            :loading="slot.processing"
                            class="btn-top-shaped btn-hover"
                            tile
                            :disabled="slot.processing || processing || disabled || disableSlotSelection"
                            :key="index2 + slot.text"
                            @click="selectSlot(slot)"
                          >
                            {{ slot.text }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        align="start"
                        dense
                        class="ma-0 pa-0 white"
                      >
                        <v-col
                          cols="2"
                          :key="index2+'d'"
                          class="my-0 pa-0"
                          style="height: 12px; border-top: 1px solid rgba(0, 0, 0, 0.1)"
                        >
                          <v-icon
                            x-small
                            style="margin-top: -12px"
                            color="red"
                            v-if="slot.isBlocked"
                          >
                            mdi-close
                          </v-icon>
                          <v-icon
                            x-small
                            style="margin-top: -12px"
                            color="green"
                            v-else
                          >
                            mdi-check
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="10"
                          :key="index2+'b'"
                          class="my-0 pa-0"
                        >
                          <v-progress-linear
                            :value="slotFilled(slot)"
                            height="6"
                            :color="slotColor(slot)"
                          />
                          <v-progress-linear
                            :value="slotReserved(slot)"
                            reverse
                            height="6"
                            color="#6E7F80"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </template>
            </v-col>
            <v-col
              cols="12"
              v-if="slotGroups !== null && slotGroups.length === 0 && !gettingAvailableTimeSlots"
            >
              <v-alert
                icon="mdi-lightbulb"
                prominent
                text
                type="info"
                class="mb-0"
              >
                <span class="text-break">
                  {{ $t('everythingIsFullyBookedForSelectedDate') }}
                </span>
              </v-alert>
            </v-col>
            <v-col
              cols="12"
              v-if="errorMessage !== null && errorMessage.length && !gettingAvailableTimeSlots"
            >
              <template v-for="(error, index) in errorMessage">
                <v-alert
                  icon="mdi-alert"
                  prominent
                  text
                  type="error"
                  :key="'error-'+index"
                >
                  <span class="text-break">
                    {{ error }}
                  </span>
                </v-alert>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        @click="close"
        color="primary"
      >
        {{ $t('close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AvailabilityLegend from "@/components/availability/AvailabilityLegend"
/**
 * @property {String} timestampInMillis
 * @property {String} paddingInMillis
 */
export default {
	name: "BookingAvailability",
	components: {AvailabilityLegend},
	props: {
		disabled: {
      type: Boolean,
    },
		disableSlotSelection: {
      type: Boolean,
    },
		location: {
      type: String,
      default: ''
    },
		item: {
      type: String,
      default: ''
    },
		channel: {
      type: String,
      default: ''
    }
	},
	data() {
		return {
			processing: false,
			selectedTimeSlots: [],
			errorMessage: [],
			selectedDate: new Date().toISOString().substr(0, 10),
		}
	},
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		availableTimeSlots() {
			return this.$store.getters.availableTimeSlots
		},
		gettingAvailableTimeSlots() {
			return this.$store.state.gettingAvailableTimeSlots
		},
		slotGroups() {

			if(this.slots === null) {
				return null
			}

			return Object.keys(this.slots)
		},
		bookingItems() {
			return this.$store.getters.bookingItems
		},
		bookingItem() {
			if(!this.bookingItems || !Array.isArray(this.bookingItems) || !this.item) {
				return null
			}
			return this.bookingItems.find(i => i.uuid === this.item)
		},
		slots() {

			if(this.availableTimeSlots === null) {
				return null
			}

			const slots = {}
			const step = 60 * 5 * 1000
			let timestamp = 1591963200000 + (60 * 30 * 1000)

			for(let i in this.availableTimeSlots) {

				const timeSlot = this.availableTimeSlots[i]

				const date = new Date(timeSlot.timestampInMillis)

				if(slots[date.getUTCHours()] === undefined) {
					slots[date.getUTCHours()] = []
				}

				slots[date.getUTCHours()].push({
					...timeSlot,
					key: date.getTime(),
					startTimeInMillis: timeSlot.timestampInMillis,
					endTimeInMillis: timeSlot.timestampInMillis + timeSlot.paddingInMillis,
					text: timeSlot.label,
					reservedForCurrentSession: timeSlot.reservedForCurrentSession,
					processing: timeSlot.processing
				})

				timestamp = timestamp + step
			}

			return slots
		},
	},
	methods: {
		slotColor(slot) {
			let slotPercentage = this.slotFilled(slot)

			if(slotPercentage >= 95) {
				return 'red'
			}
			if(slotPercentage >= 75) {
				return 'orange'
			}
			if(slotPercentage >= 50) {
				return 'yellow darken-1'
			}
			if(slotPercentage >= 25) {
				return 'primary'
			}
			return 'green'
		},
		slotFilled(slot) {
			return (slot.bookingCount / this.bookingItem.maximumGroupedQuantity) * 100
		},
		slotReserved(slot) {
			return (slot.reservationCount / this.bookingItem.maximumGroupedQuantity) * 100
		},
		close() {
			this.$emit('close')
		},
		selectSlot(slot) {
			this.$emit('selectedSlot', slot)
			this.close()
		},
		getAvailableTimeSlots(date) {

			const start = new Date(date).setUTCHours(0,0,0,0)

			const end = new Date(date).setHours(23,59,59,999)

			let item = null
			let location = null
			let channel = null

			if(this.item) {
				if(typeof this.item === 'string') {
					item = this.item
				}
				if(this.item && this.item.uuid) {
					item = this.item.uuid
				}
			}

			if(this.location) {
				if(typeof this.location === 'string') {
					location = this.location
				}
				if(this.location && this.location.uuid) {
					location = this.location.uuid
				}
			}

			if(this.channel) {
				if(typeof this.channel === 'string') {
					channel = this.channel
				}
				if(this.channel && this.channel.uuid) {
					channel = this.channel.uuid
				}
			}

			this.$store.dispatch('getAvailableTimeSlots', {
				itemUUID: item,
				locationUUID: location,
				channelUUID: channel,
				startTimeInMillis: start,
				endTimeInMillis: end
			}).catch(() => {
				this.errorMessage.push(this.$t('errorCouldNotGetAvailableTimes'))
			})
		}
	},
	watch: {
		selectedDate(value) {
			if(value) {
				this.getAvailableTimeSlots(value)
			}
		}
	}
}
</script>

<style scoped>
.visible-scroll {
	overflow-y: scroll;
	overflow: -moz-scrollbars-vertical;
}
.btn-top-shaped {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.btn-hover:hover {
	color: #1976D2 !important;
}
</style>